/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/BlockDefault'

const Hero = styled(HeroDefault)`
  height: initial;
  min-height: initial;
  max-height: initial;
  padding-top: 29%;
`

const Video = styled.iframe`
  @media (min-width: 768px) {
    width: 560px;
    height: 315px;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 250px;
  }
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  border-radius: 35px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
  position: relative;
  overflow: hidden;
  height: 100%;

  & h3 {
    font-size: ${({ theme }) => theme.font.size.xm};
    color: ${({ theme }) => theme.color.light};
  }
`

const ServiceImage = styled(Plaatjie)`
  width: 100%;
  height: 150px;

  & img {
    object-fit: cover;
    object-position: 0 0;
  }
`

const LaptopImage = styled(Plaatjie)`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`

const Background = styled.section`
  background-color: #F0F0F0;
`

const BackgroundAlt = styled.section`
  background-color: ${({ theme }) => theme.color.contrast};
  color: ${({ theme }) => theme.color.light};
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
  },
}) => (
  <Layout>
    <SEO
      yoast={yoast}
      path={path}
    />

    <section>
      <Hero small image={acf.banner.image} />
    </section>
    
    <section>
      <div className="container py-5 my-5">
        <ParseContent content={acf.youtube.description} />

        <div className="row justify-content-center mt-5">
          <div className="col-md-11 col-lg-9 col-xl-8">
            <BlockDefault className="text-center">
              <Video className="mx-auto" src={acf.youtube.youtube_url} title="Moovaz" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </BlockDefault>
          </div>
        </div>

        <div className="mt-5 text-center">
          <ButtonDefault to={acf.youtube.button.url}>
            {acf.youtube.button.title}
          </ButtonDefault>
        </div>
      </div>
    </section>

    <section>
      <div className="container my-5 py-lg-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <ParseContent content={acf.services.description} />
          </div>
        </div>

        <div className="row mt-5">
          {acf.services.items.map((item) => (
            <div className="col-6 col-md-4 col-lg mb-4" key={item.title}>
              <Service>
                <ServiceImage image={item.image} />
                <div className="p-3">
                  <h3>{item.title}</h3>
                </div>
              </Service>
            </div>
          ))}
        </div>

        <div className="mt-5 text-center">
          <ParseContent content={acf.services.description_outro} />
        </div>
      </div>
    </section>

    <Background>
      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-3 d-flex align-items-center">
            <LaptopImage image={acf.laptop.image} />
          </div>
          <div className="col-lg-7 d-flex align-items-center mt-lg-0 mt-4">
            <ParseContent content={acf.laptop.description} />
          </div>
        </div>
      </div>
    </Background>

    <section>
      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-3 d-flex align-items-center order-lg-2">
            <LaptopImage image={acf.network.image} />
          </div>
          <div className="col-lg-7 d-flex align-items-center order-lg-1 mt-lg-0 mt-4">
            <ParseContent content={acf.network.description} />
          </div>
        </div>
      </div>
    </section>

    <BackgroundAlt>
      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-3 d-flex align-items-center">
            <LaptopImage image={acf.how_it_works.image} />
          </div>
          <div className="col-lg-7 d-flex align-items-center mt-lg-0 mt-4">
            <div>
              <ParseContent content={acf.how_it_works.description} />

              <div className="mt-5">
                <ButtonDefault to={acf.how_it_works.button.url}>
                  {acf.how_it_works.button.title}
                </ButtonDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundAlt>

    <section>
      <div className="container my-5 py-5">
        <ParseContent content={acf.schmidt.description} />
      </div>
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        youtube {
          description
          youtube_url
          button {
            title
            url
          }
        }

        services {
          description
          items {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
          description_outro
        }

        laptop {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
          description
        }

        network {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
          description
        }

        how_it_works {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
          description
          button {
            title
            url
          }
        }

        schmidt {
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
